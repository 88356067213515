import { Tabs, TabsProps } from 'antd';
import Flex from 'ui/flex';
import Spacer from 'ui/spacer';

interface OsrTabs {
  actions: React.ReactNode;
  tabsItems: TabsProps['items'];
  onChange?: (e: any) => void;
}

const OsrTabs = ({ actions, tabsItems, onChange }: OsrTabs) => {
  return (
    <Spacer direction="vertical" size="small">
      <Flex justify="space-between" style={{ marginTop: '10px' }}>
        {actions}
      </Flex>

      <Spacer direction="vertical" size="small">
        <div>
          <Tabs
            onChange={(e) => (onChange ? onChange(e) : () => {})}
            items={tabsItems}
          />
        </div>
      </Spacer>
    </Spacer>
  );
};

export default OsrTabs;
