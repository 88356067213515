import { ColumnGenerationData } from 'ui/table/types';
import {
  GetDesignOsrResponse,
  GetOsrTrademarkQueryResponse,
} from 'business/resources/osr/services/types';
import { Link, generatePath } from 'react-router-dom';
import { formatDate } from 'technical/date';
import { getAlissiaResourceUrl } from 'business/resources/shared/services/constants';
import Spacer from 'ui/spacer';
import LinkList from 'ui/linkList';
import Routes from 'business/router/routes';
import { TFunction } from 'react-i18next';

//  ---- DESIGN COLUMNS ---- //

export const DESIGN_BUDGET_COLUMNS = (
  t: TFunction,
): ColumnGenerationData<GetDesignOsrResponse> => {
  return [
    {
      key: 'countryFr',
    },
    {
      key: 'protectionLabel',
      checked: false,
    },
    {
      key: 'bdlRef',
    },
    {
      key: 'misRef',
      checked: false,
    },
    {
      key: 'clientRef',
    },
    {
      key: 'clientRef2',
    },
    {
      key: 'holder',
    },
    {
      key: 'effectiveHolder',
      checked: false,
    },
    {
      key: 'title',
      ellipsis: true,
      alwaysVisible: true,
      translationKey: 'title_design',
      render: (_: any, record: GetDesignOsrResponse, index) => (
        <Link
          className="titleRedirect"
          data-test-id={`titleRedirect-${index}`}
          target="_blank"
          to={`/design/${record.assetId}`}
        >
          {record.title}
        </Link>
      ),
    },
    {
      key: 'depositDate',
    },
    {
      key: 'depositNumber',
    },
    {
      key: 'registrationDate',
    },
    {
      key: 'registrationNumber',
    },
    {
      key: 'taxNumber',
    },
    {
      key: 'dueDate',
    },
    {
      key: 'dueDateWithFine',
    },
    {
      key: 'eventStatus',
      render: (_: any, record: GetDesignOsrResponse) => {
        return t('table.common.column.eventStatus', {
          context: record.eventStatus,
        });
      },
    },
    {
      key: 'processAgentName',
    },
    {
      key: 'processAgentRef',
    },
    {
      key: 'id',
      context: 'alissiaLink',
      render: (_, data: GetDesignOsrResponse) => {
        return (
          <Spacer direction="vertical" size="middle">
            <Link
              className="titleRedirect"
              to={{ pathname: getAlissiaResourceUrl(Number(data.alissiaId)) }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Lien Alissia
            </Link>
          </Spacer>
        );
      },
    },
  ];
};

export const DESIGN_RENEW_COLUMNS = (
  t: TFunction,
): ColumnGenerationData<GetDesignOsrResponse> => {
  return [
    {
      key: 'dueDate',
    },
    {
      key: 'dueDateWithFine',
    },
    {
      key: 'taxNumber',
      width: '70px',
    },
    {
      key: 'bdlRef',
    },
    {
      key: 'misRef',
      checked: false,
    },
    {
      key: 'clientRef',
    },

    {
      key: 'title',
      ellipsis: true,
      alwaysVisible: true,
      translationKey: 'title_design',
      render: (_: any, record: GetDesignOsrResponse, index) => (
        <Link
          className="titleRedirect"
          data-test-id={`titleRedirect-${index}`}
          target="_blank"
          to={`/design/${record.assetId}`}
        >
          {record.title}
        </Link>
      ),
    },
    {
      key: 'countryCode',
      width: '200px',
      render: (_, d: GetDesignOsrResponse) => {
        if (d.countryCode === 'WO') {
          return (
            <div>
              <span>{d.countryCode}</span>
              <LinkList
                links={
                  d.internationalCountriesLinks
                    ?.filter((inter) => inter?.countryCode != 'WO')
                    .map((l) => {
                      return {
                        path: generatePath(Routes.DesignPage, {
                          id: l?.id ?? 0,
                        }),
                        label: l?.countryCode ?? '',
                      };
                    }) ?? []
                }
              />
            </div>
          );
        }
        return <span>{d.countryFr}</span>;
      },
    },
    {
      key: 'protectionLabel',
      checked: false,
    },
    {
      key: 'holder',
    },
    { key: 'effectiveHolder', checked: false },
    {
      key: 'depositDate',
    },
    {
      key: 'depositNumber',
    },
    {
      key: 'registrationDate',
      ellipsis: true,
      render: (_, data: GetDesignOsrResponse) => {
        if (data.registrationDate) {
          return formatDate(new Date(data.registrationDate));
        }
        return null;
      },
    },
    {
      key: 'registrationNumber',
    },
    {
      key: 'processAgentName',
      checked: false,
    },
    { key: 'processAgentRef', checked: false },
    { key: 'caseOpenedAt', checked: false },
    { key: 'confirmationReceipt', checked: false },
    {
      key: 'eventStatus',
      render: (_: any, record: GetDesignOsrResponse) => {
        return t('table.common.column.eventStatus', {
          context: record.eventStatus,
        });
      },
    },
    {
      key: 'id',
      context: 'alissiaLink',
      render: (_, data: GetDesignOsrResponse) => {
        return (
          <Spacer direction="vertical" size="middle">
            <Link
              className="titleRedirect"
              to={{ pathname: getAlissiaResourceUrl(Number(data.alissiaId)) }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Lien Alissia
            </Link>
          </Spacer>
        );
      },
    },
  ];
};

export const DESIGN_ACUMASS_COLUMNS: ColumnGenerationData<GetDesignOsrResponse> =
  [
    {
      key: 'nextRegistrationDate',
    },
    {
      key: 'misRef',
    },
    {
      key: 'bdlRef',
    },
    {
      key: 'clientRef',
    },
    {
      key: 'title',
      ellipsis: true,
      alwaysVisible: true,
      translationKey: 'title_design',
      render: (_: any, record: GetDesignOsrResponse, index) => (
        <Link
          className="titleRedirect"
          data-test-id={`titleRedirect-${index}`}
          target="_blank"
          to={`/design/${record.id}`}
        >
          {record.title}
        </Link>
      ),
    },
    {
      key: 'countryFr',
      render: (_, d: GetDesignOsrResponse) => {
        return <span>{d.countryFr}</span>;
      },
    },
    {
      key: 'protectionLabel',
    },
    {
      key: 'holder',
    },
    { key: 'effectiveHolder' },
    {
      key: 'depositDate',
    },
    {
      key: 'depositNumber',
    },
    {
      key: 'registrationDate',
      ellipsis: true,
      render: (_, data: GetDesignOsrResponse) => {
        if (data.registrationDate) {
          return formatDate(new Date(data.registrationDate));
        }
        return null;
      },
    },
    {
      key: 'registrationNumber',
    },
    {
      key: 'processAgentName',
    },
    { key: 'processAgentRef' },
    { key: 'caseOpenedAt' },
    { key: 'confirmationReceipt', checked: false },
    {
      key: 'status',
      checked: false,
    },
    {
      key: 'id',
      context: 'alissiaLink',
      render: (_, data: GetDesignOsrResponse) => {
        return (
          <Spacer direction="vertical" size="middle">
            <Link
              className="titleRedirect"
              to={{ pathname: getAlissiaResourceUrl(Number(data.alissiaId)) }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Lien Alissia
            </Link>
          </Spacer>
        );
      },
    },
  ];

export const DESIGN_ANOMALY_COLUMNS: ColumnGenerationData<GetDesignOsrResponse> =
  [
    {
      key: 'bdlRef',
    },
    {
      key: 'misRef',
    },
    {
      key: 'clientRef',
    },
    {
      key: 'processAgentName',
    },
    {
      key: 'processAgentRef',
    },
    {
      key: 'title',
      ellipsis: true,
      alwaysVisible: true,
      translationKey: 'title_design',
      render: (_: any, record: GetDesignOsrResponse, index) => (
        <Link
          className="titleRedirect"
          target="_blank"
          data-test-id={`titleRedirect-${index}`}
          to={`/design/${record.id}`}
        >
          {record.title}
        </Link>
      ),
    },
    {
      key: 'countryFr',
      render: (_, d: GetDesignOsrResponse) => {
        return <span>{d.countryFr}</span>;
      },
    },
    {
      key: 'protectionLabel',
    },
    {
      key: 'holder',
    },
    { key: 'effectiveHolder' },
    {
      key: 'depositDate',
    },
    {
      key: 'depositNumber',
    },
    {
      key: 'registrationDate',
      ellipsis: true,
      render: (_, data: GetDesignOsrResponse) => {
        if (data.registrationDate) {
          return formatDate(new Date(data.registrationDate));
        }
        return null;
      },
    },
    {
      key: 'registrationNumber',
    },
    { key: 'caseOpenedAt' },
    { key: 'confirmationReceipt', checked: false },
    {
      key: 'status',
      checked: false,
    },
  ];

//  ---- TRADEMARK ---- //

export const TRADEMARK_ANOMALY_COLUMNS: ColumnGenerationData<GetOsrTrademarkQueryResponse> =
  [
    {
      key: 'bdlRef',
    },
    {
      key: 'misRef',
    },

    {
      key: 'clientRef',
    },
    {
      key: 'title',
      ellipsis: true,
      alwaysVisible: true,
      translationKey: 'title_trademark',
      render: (_: any, record: GetOsrTrademarkQueryResponse, index) => (
        <Link
          className="titleRedirect"
          target="_blank"
          data-test-id={`titleRedirect-${index}`}
          to={`/trademark/${record.id}`}
        >
          {record.title}
        </Link>
      ),
    },
    {
      key: 'holder',
    },
    {
      key: 'countryFr',
      render: (_, d: GetOsrTrademarkQueryResponse) => {
        return <span>{d.countryFr}</span>;
      },
    },
    {
      key: 'renewAgentName',
    },
    {
      key: 'renewAgentRef',
    },
    {
      key: 'depositDate',
    },
    {
      key: 'depositNumber',
    },
    {
      key: 'lastRegistrationDate',
      ellipsis: true,
      render: (_, data: GetOsrTrademarkQueryResponse) => {
        if (data.lastRegistrationDate) {
          return formatDate(new Date(data.lastRegistrationDate));
        }
        return null;
      },
    },
    {
      key: 'registrationNumber',
    },
    {
      key: 'id',
      context: 'alissiaLink',
      render: (_, data: GetOsrTrademarkQueryResponse) => {
        return (
          <Spacer direction="vertical" size="middle">
            <Link
              className="titleRedirect"
              to={{ pathname: getAlissiaResourceUrl(Number(data.alissiaId)) }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Lien Alissia
            </Link>
          </Spacer>
        );
      },
    },
  ];

export const TRADEMARK_AFFIDAVIT_COLUMNS: ColumnGenerationData<GetOsrTrademarkQueryResponse> =
  [
    {
      key: 'dueDate',
    },
    {
      key: 'type',
      context: 'affidavit_done',
    },
    {
      key: 'bdlRef',
    },
    {
      key: 'misRef',
    },
    {
      key: 'title',
      ellipsis: true,
      alwaysVisible: true,
      translationKey: 'title_trademark',
      render: (_: any, record: GetOsrTrademarkQueryResponse, index) => (
        <Link
          className="titleRedirect"
          target="_blank"
          data-test-id={`titleRedirect-${index}`}
          to={`/trademark/${record.assetId}`}
        >
          {record.title}
        </Link>
      ),
    },
    {
      key: 'countryCode',
      width: '90px',
    },
    {
      key: 'lastRegistrationDate',
      render: (_: any, record: GetOsrTrademarkQueryResponse) => {
        if (record.firstRegistrationDate) {
          return formatDate(new Date(record.firstRegistrationDate));
        }
        if (record.lastRegistrationDate) {
          return formatDate(new Date(record.lastRegistrationDate));
        }
        return null;
      },
    },
    {
      key: 'grantDate',
      render: (_: any, record: GetOsrTrademarkQueryResponse) => {
        if (record.countryCode === 'US' && record.registrationDateUS) {
          return formatDate(new Date(record.registrationDateUS));
        }
        if (record.countryCode === 'JP' && record.registrationDateJP) {
          return formatDate(new Date(record.registrationDateJP));
        }
        if (record.countryCode === 'MX' && record.registrationDateMX) {
          return formatDate(new Date(record.registrationDateMX));
        }
        if (record.grantDate) {
          return formatDate(new Date(record.grantDate));
        }
        return null;
      },
    },
    {
      key: 'holder',
    },
    {
      key: 'renewAgentName',
      context: 'trademark',
    },
    {
      key: 'renewAgentRef',
      context: 'trademark',
    },
  ];

export const TRADEMARK_AFFIDAVIT_DONE_COLUMNS: ColumnGenerationData<GetOsrTrademarkQueryResponse> =
  [
    {
      key: 'dueDate',
      context: 'affidavit_done',
    },
    {
      key: 'type',
      context: 'affidavit_done',
    },
    {
      key: 'bdlRef',
    },
    {
      key: 'misRef',
    },
    {
      key: 'title',
      ellipsis: true,
      alwaysVisible: true,
      translationKey: 'title_trademark',
      render: (_: any, record: GetOsrTrademarkQueryResponse, index) => (
        <Link
          className="titleRedirect"
          target="_blank"
          data-test-id={`titleRedirect-${index}`}
          to={`/trademark/${record.assetId}`}
        >
          {record.title}
        </Link>
      ),
    },
    {
      key: 'countryCode',
      width: '90px',
    },
    {
      key: 'lastRegistrationDate',
      render: (_: any, record: GetOsrTrademarkQueryResponse) => {
        if (record.lastRegistrationDate) {
          return formatDate(new Date(record.lastRegistrationDate));
        }
        return null;
      },
    },
    {
      key: 'grantDate',
      render: (_: any, record: GetOsrTrademarkQueryResponse) => {
        if (record.countryCode === 'US' && record.registrationDateUS) {
          return formatDate(new Date(record.registrationDateUS));
        }
        if (record.countryCode === 'JP' && record.registrationDateJP) {
          return formatDate(new Date(record.registrationDateJP));
        }
        if (record.countryCode === 'MX' && record.registrationDateMX) {
          return formatDate(new Date(record.registrationDateMX));
        }
        if (record.grantDate) {
          return formatDate(new Date(record.grantDate));
        }
        return null;
      },
    },
    {
      key: 'holder',
    },
    {
      key: 'renewAgentName',
      context: 'trademark',
    },
    {
      key: 'renewAgentRef',
      context: 'trademark',
    },
    {
      key: 'confirmationReceipt',
      render: (_: any, record: GetOsrTrademarkQueryResponse) => {
        if (record.numberLabel && record.numberLabel === 'AR') {
          return record.numberLabel;
        }
      },
    },
  ];

export const TRADEMARK_RENEW_COLUMNS: ColumnGenerationData<GetOsrTrademarkQueryResponse> =
  [
    {
      key: 'dueDate',
    },
    {
      key: 'bdlRef',
      translationKey: 'bdlRef_osr',
    },

    {
      key: 'misRef',
      translationKey: 'misRef_osr',
    },
    {
      key: 'renewAgentName',
    },
    {
      key: 'renewAgentRef',
    },
    {
      key: 'title',
      translationKey: 'title_osr',
      ellipsis: true,
      alwaysVisible: true,
      render: (_: any, record: GetOsrTrademarkQueryResponse, index) => (
        <Link
          className="titleRedirect"
          target="_blank"
          data-test-id={`titleRedirect-${index}`}
          to={`/trademark/${record.assetId}`}
        >
          {record.title}
        </Link>
      ),
    },
    {
      key: 'countryFr',
      render: (_, d: GetOsrTrademarkQueryResponse) => {
        return <span>{d.countryFr}</span>;
      },
    },
    {
      key: 'protectionLabel',
    },
    {
      key: 'holder',
    },
    {
      key: 'effectiveHolder',
    },
    {
      key: 'priorityNumber',
    },
    {
      key: 'priorityDate',
      render: (_, data: GetOsrTrademarkQueryResponse) => {
        if (data.priorityDate) {
          return formatDate(new Date(data.priorityDate));
        }
        return null;
      },
    },
    {
      key: 'depositDate',
    },
    {
      key: 'depositNumber',
    },
    {
      key: 'firstRegistrationDate',
      ellipsis: true,
      render: (_, data: GetOsrTrademarkQueryResponse) => {
        if (data.firstRegistrationDate) {
          return formatDate(new Date(data.firstRegistrationDate));
        }
        return null;
      },
    },
    {
      key: 'registrationNumber',
      translationKey: 'registrationNumber_osr',
    },
    {
      key: 'classes',
      render: (_, data: GetOsrTrademarkQueryResponse) => {
        return data.classes?.map((c) => c?.classCode).join(' - ');
      },
    },
    {
      key: 'status',
      checked: false,
    },
    {
      key: 'id',
      context: 'alissiaLink',
      render: (_, data: GetOsrTrademarkQueryResponse) => {
        return (
          <Spacer direction="vertical" size="middle">
            <Link
              className="titleRedirect"
              to={{ pathname: getAlissiaResourceUrl(Number(data.alissiaId)) }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Lien Alissia
            </Link>
          </Spacer>
        );
      },
    },
  ];

export const TRADEMARK_RENEWED_COLUMNS: ColumnGenerationData<GetOsrTrademarkQueryResponse> =
  [
    {
      key: 'dueDate',
    },
    {
      key: 'bdlRef',
      translationKey: 'bdlRef_osr',
    },

    {
      key: 'misRef',
      translationKey: 'misRef_osr',
    },
    {
      key: 'renewAgentName',
    },
    {
      key: 'renewAgentRef',
    },
    {
      key: 'title',
      translationKey: 'title_osr',
      ellipsis: true,
      alwaysVisible: true,
      render: (_: any, record: GetOsrTrademarkQueryResponse, index) => (
        <Link
          className="titleRedirect"
          target="_blank"
          data-test-id={`titleRedirect-${index}`}
          to={`/trademark/${record.assetId}`}
        >
          {record.title}
        </Link>
      ),
    },
    {
      key: 'countryFr',
      render: (_, d: GetOsrTrademarkQueryResponse) => {
        return <span>{d.countryFr}</span>;
      },
    },
    {
      key: 'protectionLabel',
    },
    {
      key: 'holder',
    },
    {
      key: 'effectiveHolder',
    },
    {
      key: 'priorityNumber',
    },
    {
      key: 'priorityDate',
      render: (_, data: GetOsrTrademarkQueryResponse) => {
        if (data.priorityDate) {
          return formatDate(new Date(data.priorityDate));
        }
        return null;
      },
    },
    {
      key: 'depositDate',
    },
    {
      key: 'depositNumber',
    },
    {
      key: 'firstRegistrationDate',

      ellipsis: true,
      render: (_, data: GetOsrTrademarkQueryResponse) => {
        if (data.firstRegistrationDate) {
          return formatDate(new Date(data.firstRegistrationDate));
        }
        return null;
      },
    },
    {
      key: 'registrationNumber',
      translationKey: 'registrationNumber_osr',
    },
    { key: 'confirmationReceipt' },
    {
      key: 'classes',
      render: (_, data: GetOsrTrademarkQueryResponse) => {
        return data.classes?.map((c) => c?.classCode).join(' - ');
      },
    },
    {
      key: 'status',
      checked: false,
    },
    {
      key: 'id',
      context: 'alissiaLink',
      render: (_, data: GetOsrTrademarkQueryResponse) => {
        return (
          <Spacer direction="vertical" size="middle">
            <Link
              className="titleRedirect"
              to={{ pathname: getAlissiaResourceUrl(Number(data.alissiaId)) }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Lien Alissia
            </Link>
          </Spacer>
        );
      },
    },
  ];

export const TRADEMARK_NOT_REGISTERED_ANOMALY: ColumnGenerationData<GetOsrTrademarkQueryResponse> =
  [
    {
      key: 'clientRef',
    },
    {
      key: 'bdlRef',
      translationKey: 'bdlRef_osr',
    },
    {
      key: 'title',
      translationKey: 'title_osr',
      ellipsis: true,
      alwaysVisible: true,
      render: (_: any, record: GetOsrTrademarkQueryResponse, index) => (
        <Link
          className="titleRedirect"
          target="_blank"
          data-test-id={`titleRedirect-${index}`}
          to={`/trademark/${record.id}`}
        >
          {record.title}
        </Link>
      ),
    },

    {
      key: 'countryFr',
      render: (_, d: GetOsrTrademarkQueryResponse) => {
        return <span>{d.countryFr}</span>;
      },
    },
    {
      key: 'protectionLabel',
    },
    {
      key: 'holder',
    },
    {
      key: 'depositDate',
    },
    {
      key: 'firstRegistrationDate',

      ellipsis: true,
      render: (_, data: GetOsrTrademarkQueryResponse) => {
        if (data.firstRegistrationDate) {
          return formatDate(new Date(data.firstRegistrationDate));
        }
        return null;
      },
    },
    {
      key: 'id',
      context: 'alissiaLink',
      render: (_, data: GetOsrTrademarkQueryResponse) => {
        return (
          <Spacer direction="vertical" size="middle">
            <Link
              className="titleRedirect"
              to={{ pathname: getAlissiaResourceUrl(Number(data.alissiaId)) }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Lien Alissia
            </Link>
          </Spacer>
        );
      },
    },
  ];
