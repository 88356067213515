import { getCountryLabel } from 'technical/countriesTranslation';

import ConnectedPage from 'business/user/helpers/connectedPage';
import { useParams } from 'react-router';
import ErrorOverlay from 'ui/errorOverlay';
import HeaderTitles from 'ui/headerTitles';
import Timeline from 'ui/timeline';
import ResourceNotFound from 'business/resources/shared/components/resourceNotFound';
import { resourceExists } from 'business/common/services';
import { useGetTrademark } from 'business/resources/trademark/services/useGetTrademark';
import getTrademarkDetails from 'business/resources/trademark/services/getTrademarkDetails';
import { useGetTrademarkLink } from 'business/resources/trademark/services/useGetTrademarkLink';
import { DETAIL_IMAGE_HEIGHT } from 'ui/imageDisplay/constants';
import Spacer from 'ui/spacer';
import { getTrademarkOfficialLink } from 'business/common/services/officialRegister/getTrademarkOfficialLink';
import { Trademark } from 'business/resources/trademark/services/types';
import { useGetOfficialLinks } from 'business/resources/shared/services/links/useGetOfficialLink';
import TrademarkImage from 'business/resources/trademark/components/trademarkImage';
import ResourcePanel from 'business/resources/shared/components/resourcePanel/resourcePanel';
import OfficalLinkButton from 'business/resources/shared/components/officialLinkButton';
import ResourceLinks from 'business/common/components/resourceLinks';
import { useTranslation } from 'react-i18next';
import Routes from 'business/router/routes';
import { useTrademarkBreadcrumbs } from 'business/resources/trademark/services/useGetTrademarkBreadcrumbs';
import { useGetTrademarkEvents } from 'business/resources/trademark/services/useGetTrademarkEvents';
import { getTrademarkImageQuery } from 'business/resources/trademark/services/useGetTrademarkImages';
import getTrademarkTabs from 'business/resources/trademark/services/getTrademarkTabs';

const TrademarkPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const trademarkId = Number(id);
  const { trademark, trademarkCertificate, timeline, trademarkDataState } =
    useGetTrademark(trademarkId);
  const { data: events, tableProps: eventTableProps } =
    useGetTrademarkEvents(trademarkId);

  const { getResourceBreadcrumbs } = useTrademarkBreadcrumbs();
  const { trademarkLinks } = useGetTrademarkLink(trademark);
  const links = useGetOfficialLinks<Trademark>(
    getTrademarkOfficialLink,
    trademark,
  );

  return (
    <ResourceNotFound
      resourceExists={resourceExists(trademark, trademarkDataState)}
    >
      <ConnectedPage
        breadcrumbs={getResourceBreadcrumbs(trademark)}
        translationKey="trademarkDetails"
      >
        <Spacer direction="vertical" size="middle">
          <ErrorOverlay {...trademarkDataState}>
            <Spacer direction="vertical" size="xsmall">
              <HeaderTitles
                title={getCountryLabel(trademark?.country)}
                subtitle={trademark?.title ?? ''}
                dataTestId="trademarkPage"
                family={{
                  id: trademark?.familyId,
                  route: Routes.TrademarkFamily,
                }}
              />
              <ResourceLinks resource={trademark} />

              <Timeline events={timeline} />
            </Spacer>

            <ResourcePanel
              description={{
                data: trademark,
                panels: getTrademarkDetails(trademarkLinks, t, trademark),
              }}
              resource="trademark"
              resourceId={trademarkId}
              image={
                <TrademarkImage
                  query={getTrademarkImageQuery(trademarkId)}
                  height={DETAIL_IMAGE_HEIGHT}
                  title={trademark?.title ?? ''}
                />
              }
              additionalTabs={getTrademarkTabs({
                loading: trademarkDataState.loading,
                events: events,
                eventTableProps: eventTableProps,
                trademark: trademark,
                certificates: trademarkCertificate,
              })}
              additionalActions={links && <OfficalLinkButton link={links[0]} />}
            />
          </ErrorOverlay>
        </Spacer>
      </ConnectedPage>
    </ResourceNotFound>
  );
};

export default TrademarkPage;
