import {
  GetDesignOsrResponse,
  GetOsrTrademarkQueryResponse,
} from 'business/resources/osr/services/types';
import classes from 'business/resources/osr/components/index.module.scss';
import { addMonths, endOfMonth, subMonths, subYears } from 'date-fns';

export const handleToRenewDesignColor = (row: GetDesignOsrResponse) => {
  const nowDate = new Date();
  const dueDate = new Date(row.dueDate);
  const dueDateWithFine = row.dueDateWithFine
    ? new Date(row.dueDateWithFine)
    : undefined;

  if (row.eventStatus === 'TRAITE') {
    return classes.processed;
  }

  if (nowDate >= subMonths(endOfMonth(dueDate), 6) && nowDate < dueDate) {
    return classes.availableToRenew;
  }

  if (nowDate > dueDate && dueDateWithFine && nowDate < dueDateWithFine) {
    return classes.toRenewBeforeEnd;
  }

  if (dueDateWithFine && nowDate > dueDateWithFine) {
    return classes.dueDateExpired;
  }
};

export const budgetDesignColor = (row: GetDesignOsrResponse) => {
  if (row.eventStatus === 'TRAITE') {
    return classes.processed;
  }
};

export const handleTableRowColor = (row: GetDesignOsrResponse) => {
  const nowDate = new Date();
  const dueDate = new Date(row.dueDate);

  if (row.countryFr === 'FRANCE' || row.countryFr === 'UNION EUROPEENNE  ') {
    if (nowDate < subMonths(endOfMonth(dueDate), 6)) {
      return null;
    }
    if (nowDate >= subMonths(endOfMonth(dueDate), 6) && nowDate < dueDate) {
      return classes.availableToRenew;
    }
    if (nowDate > dueDate && addMonths(endOfMonth(dueDate), 6)) {
      return classes.toRenewBeforeEnd;
    }
    if (nowDate > addMonths(endOfMonth(dueDate), 6)) {
      return classes.dueDateExpired;
    }
  }

  if (row.countryFr === 'INTERNATIONAL') {
    if (nowDate < subMonths(dueDate, 3)) {
      return null;
    }
    if (nowDate >= subMonths(endOfMonth(dueDate), 3) && nowDate <= dueDate) {
      return classes.availableToRenew;
    }
    if (nowDate > dueDate && addMonths(endOfMonth(dueDate), 6)) {
      return classes.toRenewBeforeEnd;
    }
    if (nowDate > addMonths(dueDate, 6)) {
      return classes.dueDateExpired;
    }
  }
  if (nowDate < dueDate) {
    return classes.availableToRenew;
  }
  if (nowDate >= dueDate && nowDate <= addMonths(dueDate, 6)) {
    return classes.toRenewBeforeEnd;
  }

  if (nowDate >= addMonths(dueDate, 6)) {
    return classes.dueDateExpired;
  }
};

export const afuTrademarkTableColor = (row: GetOsrTrademarkQueryResponse) => {
  if (row.numberLabel && row.numberLabel === 'AR') {
    return classes.availableToRenew;
  }
};

export const trademarkHandleTableRowColor = (
  row: GetOsrTrademarkQueryResponse,
) => {
  const nowDate = new Date();
  const dueDate = new Date(row.dueDate);

  if (row.countryCode === 'FR') {
    if (nowDate < subYears(dueDate, 1)) {
      return null;
    }
    if (nowDate >= subYears(dueDate, 1) && nowDate < dueDate) {
      return classes.availableToRenew;
    }

    if (nowDate >= dueDate && nowDate <= addMonths(dueDate, 6)) {
      return classes.toRenewBeforeEnd;
    }

    if (nowDate >= addMonths(dueDate, 6)) {
      return classes.dueDateExpired;
    }
  }

  if (row.countryCode === 'EM' || row.countryCode === 'INTERNATIONAL') {
    if (nowDate < subMonths(dueDate, 6)) {
      return null;
    }
    if (nowDate >= subMonths(dueDate, 6) && nowDate < dueDate) {
      return classes.availableToRenew;
    }

    if (nowDate >= dueDate && nowDate <= addMonths(dueDate, 6)) {
      return classes.toRenewBeforeEnd;
    }

    if (nowDate >= addMonths(dueDate, 6)) {
      return classes.dueDateExpired;
    }
  }

  if (nowDate < dueDate) {
    return classes.availableToRenew;
  }
  if (nowDate >= dueDate && nowDate <= addMonths(dueDate, 6)) {
    return classes.toRenewBeforeEnd;
  }

  if (nowDate >= addMonths(dueDate, 6)) {
    return classes.dueDateExpired;
  }
};

export const handleRenewedDesignColor = (row: GetOsrTrademarkQueryResponse) => {
  if (row.confirmationReceipt) {
    return classes.withConfirmationReceipt;
  }
  return classes.withoutConfirmationReceipt;
};
