import { notification } from 'antd';
import { format } from 'date-fns';
import {
  ColumnsType,
  FilterType,
  InputMaybe,
  useGetXlsxDocumentLazyQuery,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import { getStorage } from 'technical/storage/parseStorageValue';
import { downloadFile as download } from 'technical/fileManagement/index';

export const useDownloadTableToXlsx = () => {
  const [downloadDocument, { loading }] = useGetXlsxDocumentLazyQuery();
  const state = useLocation<any>();
  const [searchParam, setSearchParam] = useState(
    new URLSearchParams(state.search).get('search'),
  );
  const { t } = useTranslation();

  const isBoolean = (str: string) => {
    if (str === 'true' || str === 'false') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setSearchParam(new URLSearchParams(state.search).get('search'));
  }, [state.search]);

  const getFilters = (tableName: string, advancedFilters: boolean) => {
    const keyToRemove = [
      'page',
      'pageSize',
      'orderBy',
      'family',
      'filtersOpen',
      'visibleColumns',
    ];
    let filters: Record<string, string> = {};
    if (advancedFilters) {
      filters = JSON.parse(getStorage('session').filters)[`${tableName}Search`];
      if (
        JSON.parse(getStorage('session').filters).advancedFilters[tableName]
      ) {
        filters = {
          ...filters,
          ...(JSON.parse(getStorage('session').filters).advancedFilters[
            tableName
          ].filters ?? {}),
          search: searchParam,
        };
      }
    } else {
      filters = {
        ...(JSON.parse(getStorage('session').filters)[tableName] ?? {}),
        search: searchParam,
      };
    }
    return Object.keys(filters).reduce<Record<string, any>>((acc, key) => {
      if (!keyToRemove.includes(key)) {
        acc[key] = filters[key];
      }
      if (isBoolean(filters[key])) {
        acc[key] = filters[key] === 'true';
      }
      return acc;
    }, {});
  };

  const downloadFile = async (fileName: string, document?: string | null) => {
    if (!document) {
      throw new Error('Cannot convert: document not defined');
    }
    const blob = await (await fetch(document)).blob();
    return download(blob, fileName);
  };

  const formatFileName = (tableName: string) => {
    const now = new Date();
    return `export_${tableName}-${format(now, 'yyyyMMdd_HHmm')}`;
  };

  const onExport = async (
    columns: ColumnsType[],
    tableName: string,
    advancedFilters = false,
    defaultFilters?: InputMaybe<FilterType>,
  ) => {
    try {
      const filters = {
        ...getFilters(tableName, advancedFilters),
        search: searchParam,
        ...defaultFilters,
      };
      const document = await downloadDocument({
        fetchPolicy: 'no-cache',
        variables: {
          tableName,
          columns,
          filters,
        },
      });

      return await downloadFile(
        formatFileName(tableName),
        document.data?.getXlsxFile?.data,
      );
    } catch (err) {
      logger.error(err);
      notification.error({
        message: t('errors.error', { context: 'xlsxDownload' }),
        description: t('errors.error', { context: 'xlsxDownloadDesc' }),
      });
      errorReporting.error(err);
      return undefined;
    }
  };

  return {
    onExport,
    loading,
  };
};
