import { generatePathByAssetType } from 'business/common/services';
import { getCountryColumn } from 'technical/countriesTranslation/constants';
import { TrademarkEvent } from 'business/resources/events/services/types';
import { Link } from 'react-router-dom';
import ResourceImageDisplay from 'ui/imageDisplay';
import {
  EVENTS_TABLE_IMAGE_CELL_WIDTH,
  EVENTS_TABLE_IMAGE_STYLE,
} from 'ui/imageDisplay/constants';
import { ColumnGenerationData } from 'ui/table/types';
import { getTrademarkImageQuery } from 'business/resources/trademark/services/useGetTrademarkImages';

const getTrademarkEventColumns = (): ColumnGenerationData<TrademarkEvent> => [
  {
    key: 'type',
    alwaysVisible: true,
    translationKey: 'eventType',
    ellipsis: true,
  },
  { key: 'holder', checked: false, ellipsis: true },
  { key: 'effectiveHolder', checked: false, ellipsis: true },
  {
    key: 'title',
    translationKey: 'title_design',
    alwaysVisible: true,
    render: (_, { assetId, title }: TrademarkEvent) => (
      <Link
        data-test-id="titleRedirect"
        className="titleRedirect"
        to={generatePathByAssetType('trademark', assetId)}
      >
        {title}
      </Link>
    ),
  },
  {
    key: 'id',
    translationKey: 'image',
    alwaysVisible: true,
    sorter: false,
    width: EVENTS_TABLE_IMAGE_CELL_WIDTH,
    render: (_, { assetId }) =>
      assetId ? (
        <ResourceImageDisplay
          {...EVENTS_TABLE_IMAGE_STYLE}
          query={getTrademarkImageQuery(assetId)}
        />
      ) : null,
  },
  {
    key: 'classes',
    alwaysVisible: true,
    render: (_, record) => {
      return record.classes?.map((c) => c?.classCode).join(' - ');
    },
  },
  { ...getCountryColumn(), alwaysVisible: true },
  { key: 'dueDate', alwaysVisible: true, ellipsis: true },
  { key: 'depositNumber', alwaysVisible: true, ellipsis: true },
  { key: 'depositDate', checked: false, ellipsis: true },
  { key: 'registrationNumber', checked: false, ellipsis: true },
  { key: 'firstRegistrationDate', checked: false, ellipsis: true },
  { key: 'lastRegistrationDate', checked: false, ellipsis: true },
  { key: 'bdlRef', checked: false, ellipsis: true },
  { key: 'clientRef', checked: false, ellipsis: true },
  { key: 'clientRef2', checked: false, ellipsis: true },
  { key: 'bdlOfficer', ellipsis: true },
  { key: 'clientOfficer', ellipsis: true },
];

export default getTrademarkEventColumns;
