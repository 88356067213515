import {
  useGetRelatedTrademarksQuery,
  useGetTrademarkQuery,
} from 'generated/graphql';
import { getTrademarkTimelineEvents } from './getTrademarkTimeline';
import { useTranslation } from 'react-i18next';

export const useGetTrademark = (id: number) => {
  const { t } = useTranslation();
  const { data, ...trademarkDataState } = useGetTrademarkQuery({
    variables: { id },
  });

  const trademark = data?.trademark_by_pk ?? null;

  const { data: relatedTrademark } = useGetRelatedTrademarksQuery({
    variables: {
      id: trademark?.id,
      familyId: trademark?.familyId,
      countryCode: trademark?.country?.code,
      depositNumber: trademark?.depositNumber,
    },
    skip: !trademark?.id,
  });

  // récupérer les certificats de la fiche et les certificats des fiches renouvelés
  const relatedTrademarkCertificate = relatedTrademark?.related_trademarks.map(
    (certif) => certif.trademarkCertificate,
  );

  const trademarkCertificate = [
    ...(trademark?.trademarkCertificate ?? []),
    ...(relatedTrademarkCertificate ?? []),
  ].flat();

  return {
    trademark,
    trademarkDataState,
    trademarkCertificate: trademarkCertificate,
    timeline: getTrademarkTimelineEvents(t, trademark),
  };
};
