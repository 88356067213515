import Button from 'ui/button';
import { OsrFilterState } from 'business/resources/osr/services/types';
import Table from 'ui/table';
import {
  TRADEMARK_AFFIDAVIT_COLUMNS,
  TRADEMARK_AFFIDAVIT_DONE_COLUMNS,
} from 'business/resources/osr/components/common/osrTableColumns';
import { useGenerateExcelFile } from 'business/resources/osr/services/useGenerateExcelFile';
import { useGetTrademarkOsrData } from 'business/resources/osr/services/useGetTrademarkOsrData';
import OsrTabs from 'business/resources/osr/components/common/OsrTabs';
import Flex from 'ui/flex';
import { RangePicker } from 'ui/date/datePicker';
import { endOfDay, endOfYear, startOfYear } from 'date-fns';
import Spacer from 'ui/spacer';
import { Checkbox } from 'antd';
import { useState } from 'react';
import { afuTrademarkTableColor } from 'business/resources/osr/components/common/osrRowColors';

interface ITrademarkAffidavits {
  filters: OsrFilterState;
  onChangeRangeDate: (from: Date | null, to: Date | null) => void;
}

const TrademarkAffidavits = (props: ITrademarkAffidavits) => {
  const [lastDeadlineAffidavit, setLastDeadlineAffidavit] = useState(true);
  const [firstAffidavitEvent, setFirstAffidavitEvent] = useState(false);
  const [affidavitAr, setAffidavitAr] = useState(false);
  const [defaultTable, setDefaultTable] = useState('affidavitsTable');
  const { generateExcel } = useGenerateExcelFile('trademark', props.filters);
  const { data: affidavitData, tableProps: affidavitTableProps } =
    useGetTrademarkOsrData('AFFIDAVIT', {
      dueDate: props.filters.dueDate,
      companyRef: props.filters.companyRef,
      countryCode: props.filters.countryCode,
      lastDeadlineAffidavit,
      firstAffidavitEvent,
    });

  const { data: affidavitDoneData, tableProps: affidavitDoneTableProps } =
    useGetTrademarkOsrData('AFFIDAVIT_DONE', {
      dueDate: props.filters.dueDate,
      companyRef: props.filters.companyRef,
      countryCode: props.filters.countryCode,
      affidavitAr,
    });

  return (
    <OsrTabs
      onChange={(e) => setDefaultTable(e)}
      tabsItems={[
        {
          key: 'affidavitsTable',
          label: `Affidavits à traiter (${affidavitTableProps.pagination?.total})`,
          children: (
            <Table
              editButtonEnabled={false}
              searchBarEnabled={false}
              defaultColumns={TRADEMARK_AFFIDAVIT_COLUMNS}
              name="affidavitTable"
              {...affidavitTableProps}
              dataSource={affidavitData}
            />
          ),
        },
        {
          key: 'affidavitDoneTable',
          label: `Affidavits traités (${affidavitDoneTableProps.pagination?.total})`,
          children: (
            <Table
              handleTableRowColor={afuTrademarkTableColor}
              editButtonEnabled={false}
              searchBarEnabled={false}
              defaultColumns={TRADEMARK_AFFIDAVIT_DONE_COLUMNS}
              name="affidavitDoneTable"
              {...affidavitDoneTableProps}
              dataSource={affidavitDoneData}
            />
          ),
        },
      ]}
      actions={
        <Spacer size="small" direction="vertical">
          <Flex column style={{ width: '33%' }}>
            <Spacer direction="vertical" size="small">
              <RangePicker
                allowClear
                inputReadOnly={true}
                defaultValue={[startOfYear(new Date()), endOfYear(new Date())]}
                onChange={(e) => {
                  props.onChangeRangeDate(
                    e && e[0] ? new Date(endOfDay(e[0])) : e?.[0] ?? null,
                    e && e[1] ? new Date(endOfDay(e[1])) : e?.[1] ?? null,
                  );
                }}
              />
              <Spacer align="center">
                <Button
                  type="primary"
                  onClick={() => generateExcel('AFFIDAVIT')}
                >
                  Exporter la liste des affidavits
                </Button>
                {defaultTable === 'affidavitsTable' ? (
                  <>
                    <Checkbox
                      defaultChecked={firstAffidavitEvent}
                      onChange={() =>
                        setFirstAffidavitEvent(!firstAffidavitEvent)
                      }
                    >
                      1er délai affidavit
                    </Checkbox>
                    <Checkbox
                      defaultChecked={lastDeadlineAffidavit}
                      onChange={() =>
                        setLastDeadlineAffidavit(!lastDeadlineAffidavit)
                      }
                    >
                      Dernier délai affidavit
                    </Checkbox>
                  </>
                ) : (
                  <Checkbox
                    defaultChecked={affidavitAr}
                    onChange={() => setAffidavitAr(!affidavitAr)}
                  >
                    Sans AR
                  </Checkbox>
                )}
              </Spacer>
            </Spacer>
          </Flex>
        </Spacer>
      }
    />
  );
};

export default TrademarkAffidavits;
