import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { ColumnType as AntdColumnType } from 'antd/lib/table';
import { ColumnTitle } from 'antd/lib/table/interface';
import StatusBadge from 'business/common/components/statusBadge';
import { ColumnsType } from 'generated/graphql';
import { TFunction } from 'i18next';
import { isCountryKey } from 'technical/countriesTranslation';
import { formatBytes } from 'technical/fileManagement';
import {
  OrderBy,
  orderByToDefaultSortOrder,
} from 'technical/filters/parser/commonParser/orderByParser';
import { priceFormat } from 'technical/format';
import { isDefined } from 'technical/validation';
import { i18n } from 'translations';
import Flex from 'ui/flex';
import { ColumnDataType, ColumnGenerationData } from './types';

/**
 * Make a field name correspond to a specific column type
 */
export const fieldToColumDataType: Record<string, ColumnDataType> = {
  status: 'status',
  VATComputedTotal: 'priceInCents',
  createdAt: 'date',
  dueDate: 'date',
  grantDate: 'date',
  reminderDate: 'date',
  reservationDate: 'date',
  publicationDate: 'date',
  dueDateWithFine: 'date',
  expirationDate: 'date',
  firstDepositDate: 'date',
  nextRegistrationDate: 'date',
  lastRegistrationDate: 'date',
  depositDate: 'date',
  issueDate: 'date',
  registrationDate: 'date',
  caseOpenedAt: 'date',
  subscriptionDate: 'shortDate',
  subscriptionEndDate: 'shortDate',
  lastArchiveUploaded: 'date',
  sendDate: 'dateAndTime',
  lastConnection: 'dateAndTime',
  lastFetched: 'dateAndTime',
  date: 'dateAndTime',

  totalSize: 'bytes',
  averageSize: 'bytes',

  adjournedPublication: 'boolean',
  enabledMFA: 'boolean',
} as const;

type RenderFunction = (value: any) => React.ReactNode;

export const getDateFormatter = () =>
  new Intl.DateTimeFormat(i18n.language, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

export const getShortDateFormatter = () =>
  new Intl.DateTimeFormat(i18n.language, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });

const getDateTimeFormatter = () =>
  new Intl.DateTimeFormat(i18n.language, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

/**
 * Override rules for specific column types
 */

export type RenderType = <T>(
  t: TFunction,
) => Omit<AntdColumnType<T>, 'render' | 'key'> & { render?: RenderFunction };

export const getColumnsDataType = (): Record<ColumnDataType, RenderType> => ({
  text: () => ({}),
  status: () => ({
    width: 100,
    render: (status: string) => (
      <Flex justify="center">
        <StatusBadge status={status} />
      </Flex>
    ),
  }),
  date: () => ({
    render: (date: string) =>
      date ? getDateFormatter().format(new Date(date)) : '',
  }),
  dateAndTime: () => ({
    render: (date: string) =>
      date ? getDateTimeFormatter().format(new Date(date)) : '',
  }),
  priceInCents: () => ({
    render: (price: number) => priceFormat(i18n.language).format(price / 100),
  }),
  bytes: () => ({
    render: (bytes: number) => formatBytes(bytes),
  }),
  shortDate: () => ({
    render: (date: string) =>
      date ? getShortDateFormatter().format(new Date(date)) : '',
  }),
  boolean: (t) => ({
    render: (value?: boolean | null) =>
      isDefined(value) ? t(`common.${value ? 'yes' : 'no'}`) : '',
  }),
});

export const getCustomRendererForField = (
  field: string,
  t: TFunction,
): RenderFunction | null => {
  const type = fieldToColumDataType[field];
  return type ? getColumnsDataType()[type](t).render ?? null : null;
};

export const ellipsisStyle = {
  style: {
    whitespace: 'nowrap',
    maxWidth: 300,
  },
};

export function defaultColumn<T>(
  t: TFunction,
  { key, context, translationKey }: ColumnGenerationData<T>[number],
): Omit<AntdColumnType<T>, 'key'> & {
  key: keyof T;
} {
  const title = t(`table.common.column.${translationKey ?? String(key)}`, {
    context,
  });
  return {
    key,
    dataIndex: String(key),
    title,
    showSorterTooltip: false,
    sorter: true,
    align: 'center',
  };
}

export function generateDefaultVisibleColumnsKey<T>(
  columns: ColumnGenerationData<T>,
): (keyof T)[] {
  return columns
    .filter((column) => {
      return (
        !column.alwaysVisible && !column.disabled && column.checked !== false
      );
    })
    .reduce<(keyof T)[]>((acc, column) => {
      return column.disabled ? acc : [...acc, column.key];
    }, []);
}

export function generateColumns<T>(
  columns: ColumnGenerationData<T>,
  t: TFunction,
  defaultSort: OrderBy<T>,
): ColumnGenerationData<T> {
  return columns.map((column) => {
    const { key, dataType } = column;
    return {
      ...defaultColumn(t, column),
      ...getColumnsDataType()[
        dataType ?? fieldToColumDataType[String(key)] ?? 'text'
      ](t),
      ...column,
      defaultSortOrder: orderByToDefaultSortOrder(defaultSort[key]),
    };
  });
}

function isTitleString<T>(title: ColumnTitle<T>): title is string {
  return title !== undefined && title !== null && typeof title === 'string';
}

export function generateColumnsEditionItems<T>(
  defaultColumns: ColumnGenerationData<T>,
) {
  return defaultColumns
    .filter((column) => !column.alwaysVisible && !column.disabled)
    .reduce<{ label: string; value: boolean; key: string }[]>((cols, col) => {
      if (col && isTitleString(col.title)) {
        return [
          ...cols,
          {
            label: col.title,
            key: col.key,
            value: col.checked ?? true,
          },
        ];
      }
      return cols;
    }, []);
}

function isCheckedColumnKey<T>(
  checkedColumnKey: CheckboxValueType,
  columnsProperties: string[],
): checkedColumnKey is Extract<keyof T, string> {
  return (
    typeof checkedColumnKey === 'string' &&
    columnsProperties.includes(checkedColumnKey)
  );
}

export function formatCheckedColumnsToVisibleKey<T>(
  checkedColumnsKey: CheckboxValueType[],
  columnsProperties: Extract<keyof T, string>[],
) {
  return checkedColumnsKey.reduce(
    (cols: Extract<keyof T, string>[], checkedCol) => {
      if (isCheckedColumnKey(checkedCol, columnsProperties)) {
        cols.push(checkedCol);
      }
      return cols;
    },
    [],
  );
}

/**
 * Utilisé pour formatter les colonnes pour le nom des headers d'un fichier xlsx
 */
export const formatColumnsToColumnType = <T,>(
  columns: ColumnGenerationData<T>,
): ColumnsType[] => {
  return columns.map((c) => ({
    key: c.key, // correspond à l'index de la colonne (id, bdlRef, firstDepositDate...)
    value: isTitleString(c.title) ? c.title : '', // correspond à la traduction de la colonne
  }));
};

export const columnVisibilityStorageKey = (tableId: string) =>
  `table-${tableId}-visible-columns`;

export const parseVisibleColumnsFromStorage =
  <T,>(columns: ColumnGenerationData<T>) =>
  (storageString: string): null | (keyof T)[] => {
    const visibleColumns = JSON.parse(storageString);
    if (Array.isArray(visibleColumns)) {
      return visibleColumns
        .map((col) => {
          return isCountryKey(col)
            ? columns.find(({ key }) => isCountryKey(key))?.key
            : col;
        })
        .filter(
          (col) =>
            typeof col === 'string' && columns.find(({ key }) => key === col),
        );
    }
    return null;
  };
