import {
  GetOsrDataListQuery,
  GetOsrDataListQueryVariables,
  useGetOsrDataListQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { GetOsrTrademarkQueryResponse, OsrTrademarkTableType } from './types';

export const useGetTrademarkOsrData = (
  type: OsrTrademarkTableType,
  filters: any,
) => {
  return useQueryForTable<
    GetOsrTrademarkQueryResponse,
    GetOsrDataListQuery,
    GetOsrDataListQueryVariables
  >({
    subStorageKey: `trademark_${type}_table`,
    useQuery: ({ limit, offset, orderBy, searchText }) => {
      console.log('orderr = ', orderBy);
      return useGetOsrDataListQuery({
        fetchPolicy: 'no-cache',
        variables: {
          limit,
          offset,
          where: {
            dueDate: filters.dueDate,
            countryCode: filters.countryCode,
            office: filters.office,
            search: searchText,
            companyRef: filters.companyRef,
            firstAffidavitEvent: filters.firstAffidavitEvent,
            lastDeadlineAffidavit: filters.lastDeadlineAffidavit,
            affidavitAr: filters.affidavitAr,
            type,
          },
          orderBy: Object.keys(orderBy).length
            ? orderBy
            : ['RENEWED', 'TO_RENEW', 'AFFIDAVIT', 'AFFIDAVIT_DONE'].includes(
                type,
              )
            ? { dueDate: 'desc' }
            : {},
        },
      });
    },
    map: (res) => res?.getTrademarkOsrData?.resources ?? [],

    getTotal: (res) => res?.getTrademarkOsrData?.count ?? 0,
  });
};
